import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as R from 'ramda';
import qs from 'qs';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { BET_SLIP_STATE, NO_RECOMMENDATION_ID, STAKE_TYPES_WITH_3WAY_MAIN_MARKET } from 'core/constants';
import { getConfig, getMainLineMarkets, isEmptyOrNil } from 'core/helpers';

import { RouteLink } from 'components/route-link/route-link';
import { TeamColumn } from 'components/betting-table/team-column/team-column';
import { IconChevron } from 'components/icons/icon-chevron/icon-chevron';
import { IconLivestreamSmall } from 'components/icons/icon-livestream-small/icon-livestream-small';
import { IconArrowControl } from 'components/icons/icon-arrow-control/icon-arrow-control';

import { OddsCell } from 'components/betting-table/odds-cell/odds-cell';
import { GameOutcomeDropdown } from 'components/betting-table/game-outcome-dropdown/game-outcome-dropdown';
import { DateField } from 'components/betting-table/date-field/date-field';
import { GameStatus } from 'components/betting-table/game-status/game-status';
import { prepareHomeScore, prepareAwayScore } from 'helpers/score';
import { setEmptyStakes } from 'helpers/stake';
import { GA } from 'helpers/ga';

import { GameHead } from '../game-head/game-head';
import { WatchlistMenu } from '../watchlist-menu/watchlist-menu';
import { SPORTS_WITHOUT_LIVE_SCORE, PAGE_NAMES, SPORTS_WITH_3WAY_MAIN_MARKET } from '../../../constants';
import colors from '../../../customizations/js/color-variables';

import './game-row.scss';

const IS_LIVE_STREAMS_ENABLED = getConfig('IS_LIVE_STREAMS_ENABLED');

const SCROLL_OFFSET = 100;

export class GameRow extends Component {
  static propTypes = {
    locale: PropTypes.string.isRequired,
    game: PropTypes.shape().isRequired,
    toggleStake: PropTypes.func.isRequired,
    isLeagueType: PropTypes.bool,
    stakeTypes: PropTypes.arrayOf(PropTypes.shape()),
    betSlipStakes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    betSlipState: PropTypes.oneOf([
      BET_SLIP_STATE.DEFAULT,
      BET_SLIP_STATE.SUSPENDED,
      BET_SLIP_STATE.BET_FACTOR_DECREASED,
      BET_SLIP_STATE.SUCCESS,
      BET_SLIP_STATE.ERROR,
    ]).isRequired,
    isWatchlist: PropTypes.bool.isRequired,
    updateWatchlist: PropTypes.func,
    isResults: PropTypes.bool.isRequired,
    isSchedule: PropTypes.bool.isRequired,
    pageName: PropTypes.string.isRequired,
    recommendationRef: PropTypes.string,
    leagueName: PropTypes.string.isRequired,
    isLeagueClickable: PropTypes.bool.isRequired,
    groupDate: PropTypes.string,
    withTableTitle: PropTypes.bool.isRequired,
    sendLeagueClickFeedback: PropTypes.func.isRequired,
    sendEventClickFeedback: PropTypes.func.isRequired,
    sectionBeforeEventPage: PropTypes.string,
    liveSportId: PropTypes.number,
  };

  static defaultProps = {
    isLeagueType: false,
    updateWatchlist: null,
    stakeTypes: null,
    recommendationRef: null,
    groupDate: null,
    sectionBeforeEventPage: null,
    liveSportId: null,
  };

  oddsRef = createRef();
  gameOutcomeFiltersRef = createRef();

  resizeWindowSubsription;

  state = {
    isPrevShowed: false,
    isNextShowed: false,
  };

  componentDidMount() {
    const odds = this.oddsRef && this.oddsRef.current;

    if (odds) {
      const { scrollWidth, clientWidth } = odds;
      this.resizeWindowSubsription = fromEvent(window, 'resize')
        .pipe(debounceTime(200))
        .subscribe(this.onScroll);

      if (scrollWidth > clientWidth) {
        this.setIsNextShowed(true);
      }
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !R.equals(this.props, nextProps) || !R.equals(this.state, nextState);
  }

  componentDidUpdate(prevProps) {
    const { game: { stakeTypes: prevStakeTypes } } = prevProps;
    const { game: { stakeTypes } } = this.props;

    if (!R.equals(prevStakeTypes, stakeTypes)) {
      this.onScroll();
    }
  }

  componentWillUnmount() {
    if (this.resizeWindowSubsription) {
      this.resizeWindowSubsription.unsubscribe();
    }
  }

  setIsNextShowed = isNextShowed => this.setState({ isNextShowed });

  setIsPrevShowed = isPrevShowed => this.setState({ isPrevShowed });

  onRemoveClick = () => {
    const { isWatchlist, updateWatchlist, game } = this.props;

    if (isWatchlist && updateWatchlist) {
      updateWatchlist(game);
      GA.event({
        category: 'watchlist',
        label: 'delete-page',
      });
    }
  };

  onClick = () => {
    const {
      isWatchlist,
      isSchedule,
      pageName,
      game: {
        eventNumber,
        isLive,
        eventStatus,
        recommendationId,
      },
      sendEventClickFeedback,
    } = this.props;
    const isEventInLive = isLive || eventStatus !== null;
    let category;
    let label;

    if (isWatchlist) {
      category = 'watchlist';
      label = 'event-click';
    } else if (isSchedule) {
      category = 'calendar';
      label = 'event-click';
    } else if (pageName === PAGE_NAMES.HOME) {
      category = 'go-to-event-page';

      if (isEventInLive) {
        label = 'inplay-section-main-to-event';
      } else {
        label = 'upcoming-section-main-to-event';
      }
    } else if (pageName === PAGE_NAMES.SPORT) {
      category = 'go-to-event-page';

      if (isEventInLive) {
        label = 'inplay-section-sports-to-event';
      } else {
        label = 'upcoming-section-sports-to-event';
      }
    } else if (pageName === PAGE_NAMES.LEAGUE) {
      category = 'go-to-event-page';

      if (isEventInLive) {
        label = 'inplay-section-league-to-event';
      } else {
        label = 'upcoming-section-league-to-event';
      }
    } else if (pageName === PAGE_NAMES.LIVE) {
      category = 'go-to-event-page';
      label = 'main-inplay-to-event';
    }

    if (category && label) {
      GA.event({
        category,
        label,
      });
    }

    sendEventClickFeedback(eventNumber, isEventInLive, recommendationId);
  };

  onScroll = () => {
    const { isPrevShowed, isNextShowed } = this.state;

    if (this.oddsRef && this.oddsRef.current) {
      const { scrollWidth, clientWidth, scrollLeft } = this.oddsRef.current;
      const preparedWidth = Math.ceil(scrollLeft + clientWidth);

      if (isPrevShowed) {
        if (scrollLeft === 0) {
          this.setIsPrevShowed(false);
        }
      } else if (scrollLeft > 0) {
        this.setIsPrevShowed(true);
      }

      if (isNextShowed) {
        if (preparedWidth >= scrollWidth) {
          this.setIsNextShowed(false);
        }
      } else if (preparedWidth < scrollWidth) {
        this.setIsNextShowed(true);
      }
    }
  };

  onPrevClick = () => {
    const odds = this.oddsRef && this.oddsRef.current;

    if (odds) {
      const { scrollLeft } = odds;
      odds.scrollTo({
        left: scrollLeft - SCROLL_OFFSET,
        behavior: 'smooth',
      });
    }
  };

  onNextClick = () => {
    const odds = this.oddsRef && this.oddsRef.current;

    if (odds) {
      const { scrollLeft } = odds;
      odds.scrollTo({
        left: scrollLeft + SCROLL_OFFSET,
        behavior: 'smooth',
      });
    }
  };

  render() {
    const {
      locale,
      game,
      toggleStake,
      isLeagueType,
      betSlipStakes,
      betSlipState,
      isWatchlist,
      updateWatchlist,
      isResults,
      isSchedule,
      pageName,
      recommendationRef,
      leagueName,
      isLeagueClickable,
      groupDate,
      withTableTitle,
      sendLeagueClickFeedback,
      sectionBeforeEventPage,
      liveSportId,
    } = this.props;
    const { isPrevShowed, isNextShowed } = this.state;
    const {
      eventId,
      eventNumber,
      oddsCount,
      isBetAllowed,
      isLive,
      teamA,
      teamB,
      timestamp,
      stakeTypes,
      sportId,
      passedTime,
      eventStatus,
      homeScore,
      awayScore,
      isOutright,
      leagueId,
      teamALogo,
      teamBLogo,
      hasLiveStream,
      setAltScore,
      recommendationId,
    } = game;
    let is3way = SPORTS_WITH_3WAY_MAIN_MARKET.includes(sportId) && !isOutright;
    const withRecommendation = pageName === PAGE_NAMES.LIVE && recommendationRef;
    const preparedRecommendationId = withRecommendation ? recommendationId || NO_RECOMMENDATION_ID : null;
    const isEventInLive = isLive || eventStatus !== null;
    const gameUrl = `/event/${eventId}?${qs.stringify({
      isLive: isEventInLive,
      recommendationId: preparedRecommendationId,
      recommendationRef,
      sectionBeforeEventPage,
    }, { skipNulls: true })}`;
    const marketsList = getMainLineMarkets({
      stakeTypes, isLive, eventStatus, betSlipStakes, betSlipState
    });
    const isAnyMarketExists = !!marketsList.length
    && !!marketsList.filter(({ marketStake }) => marketStake && !!marketStake.length).length;
    const preparedHomeScore = prepareHomeScore(homeScore, sportId, setAltScore);
    const preparedAwayScore = prepareAwayScore(awayScore, sportId, setAltScore);
    const isClickable = !isResults && (!isSchedule || (isSchedule && eventId && oddsCount));
    const LinkComponent = isClickable ? RouteLink : 'span';

    const oddProps = {
      isBetAllowed,
      toggleStake,
      eventId,
      eventNumber,
      teamA,
      teamB,
      betSlipStakes,
      isEventInLive,
      betSlipState,
      isOutright,
      leagueId,
      isResults,
      pageName,
      isWatchlist,
      isSchedule,
      recommendationId,
      liveSportId,
    };

    return (
      <div className="game-row-wrapper">
        <GameHead
          locale={locale}
          sportId={sportId}
          leagueId={leagueId}
          leagueName={leagueName}
          isLeagueType={isLeagueType}
          groupDate={groupDate}
          withTableTitle={withTableTitle}
          isWatchlist={isWatchlist}
          isResults={isResults}
          isSchedule={isSchedule}
          isClickable={isLeagueClickable}
          isAllowToggle={false}
          sendLeagueClickFeedback={sendLeagueClickFeedback}
        />
        <div className="game-row bg-main-2">
          <div className="betting-table-row d-flex flex-nowrap justify-content-between">
            <LinkComponent
              to={gameUrl}
              locale={locale}
              className={classNames('betting-table-link flex-fill d-flex justify-content-between align-items-center overflow-hidden', {
                'is-clickable': isClickable,
              })}
              onClick={this.onClick}
            >
              <div className="d-flex overflow-hidden">
                <div className="betting-date-field-wrapper d-flex align-items-center justify-content-center flex-shrink-0 text-center text-extra-2">
                  <span className={classNames('betting-date-field', {
                    caption: isEventInLive,
                    upcoming: !isEventInLive,
                  })}
                  >
                    <span className="animate-minute">
                      {isEventInLive
                        ? (
                          <div className="d-flex flex-column justify-content-center align-items-center">
                            <GameStatus
                              locale={locale}
                              sportId={sportId}
                              eventStatus={eventStatus}
                              passedTime={passedTime}
                              className="game-status label text-break overflow-hidden"
                            />
                            {IS_LIVE_STREAMS_ENABLED && hasLiveStream && <IconLivestreamSmall className="mt-0_5" />}
                          </div>
                        )
                        : (
                          <DateField
                            locale={locale}
                            isTimeFormat={isLeagueType || isResults || isSchedule}
                            timestamp={timestamp}
                          />
                        )}
                    </span>
                  </span>
                </div>

                <div className={classNames('d-flex flex-column overflow-hidden', {
                  'justify-content-center': isOutright,
                })}
                >
                  <TeamColumn name={teamA} logo={teamALogo} sportId={sportId} />
                  {!isOutright && <TeamColumn name={teamB} logo={teamBLogo} sportId={sportId} className="mt-0_25" />}
                </div>
              </div>

              {(isEventInLive || isResults) && !SPORTS_WITHOUT_LIVE_SCORE.includes(sportId) && (
                <div className={classNames('d-flex flex-column pr-2 caption font-weight-bold text-right', { 'text-success': !isResults })}>
                  {preparedHomeScore === '' ? <span className="mb-1">&nbsp;</span> : <span className="mb-1">{preparedHomeScore}</span>}
                  {preparedAwayScore === '' ? <span>&nbsp;</span> : <span>{preparedAwayScore}</span>}
                </div>
              )}
            </LinkComponent>

            <div className="odds-container position-relative d-flex justify-content-end flex-wrap flex-nowrap flex-shrink-0">
              <div
                ref={this.gameOutcomeFiltersRef}
                className="game-row-odds-wrapper position-absolute"
              >
                <div
                  ref={this.oddsRef}
                  onScroll={this.onScroll}
                  className="game-row-odds-scroll-bar d-flex"
                >
                  {isAnyMarketExists ? (
                    marketsList.map(({
                      stakeTypeId,
                      stakeTypeName,
                      marketStake,
                      stakes,
                      isTotal,
                      isHandicap,
                    }, index) => {
                      const isOutcomesDropdown = (isHandicap || isTotal) && stakes.length > 1;
                      is3way = STAKE_TYPES_WITH_3WAY_MAIN_MARKET.includes(stakeTypeId);
                      const isLastMarket = marketsList.length === index + 1;
                      const marketStakeWithEmptyValue = setEmptyStakes(marketStake, is3way);

                      return !R.isNil(marketStakeWithEmptyValue) && (
                        <div
                          key={stakeTypeId}
                          className={classNames('game-row-odds-item d-flex flex-column flex-shrink-0 position-relative',
                            {
                              'with-draw': is3way,
                              'with-dropdown': isOutcomesDropdown,
                              'mr-1': !isOutcomesDropdown && !isLastMarket
                            })}
                        >
                          <div className="market-name overflow-hidden caption position-absolute text-center mt-1">
                            <span className="text-nowrap text-extra-3">
                              {stakeTypeName}
                            </span>
                          </div>
                          <div className="d-flex align-items-center flex-shrink-0 mt-4">
                            <div className="game-row-odds-group overflow-hidden">
                              {marketStakeWithEmptyValue.map(stake =>
                                !isEmptyOrNil(stake) ? (
                                  <OddsCell
                                    key={stake.stakeId}
                                    stake={stake}
                                    isHandicap={isHandicap}
                                    isTotal={isTotal}
                                    {...oddProps}
                                  />
                                ) : (
                                  <div className="odd-cell is-not-allowed d-flex justify-content-center align-items-center text-extra-3">
                                    &#8212;
                                  </div>
                                ))}
                            </div>
                            {isOutcomesDropdown && (
                              <GameOutcomeDropdown
                                gameOutcomeFiltersRef={this.gameOutcomeFiltersRef}
                                className="ml-0_25 mr-0_5"
                                classNameMenu={classNames('game-row-dropdown-menu', { 'is-first': index === 0 })}
                                stakes={stakes}
                                isTotal={isTotal}
                                isHandicap={isHandicap}
                                oddProps={oddProps}
                              />
                            )}
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className={classNames('game-row-odds-item', { 'with-draw': is3way })}>
                      <div className="game-row-odds-group overflow-hidden">
                        <div className="odd-cell is-not-allowed d-flex justify-content-center align-items-center text-extra-3">
                          &#8212;
                        </div>
                        <div className="odd-cell is-not-allowed d-flex justify-content-center align-items-center text-extra-3">
                          &#8212;
                        </div>
                        {is3way && (
                          <div className="odd-cell is-not-allowed d-flex justify-content-center align-items-center text-extra-3">
                            &#8212;
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>

                <div className={classNames('game-row-odds-prev position-absolute d-flex align-items-center', { invisible: !isPrevShowed })}>
                  <div
                    role="button"
                    tabIndex="0"
                    onClick={this.onPrevClick}
                    onKeyPress={this.onPrevClick}
                    className="game-row-odds-prev-btn d-flex justify-content-center align-items-center position-relative"
                  >
                    <IconArrowControl />
                  </div>
                </div>

                <div className={classNames('game-row-odds-next position-absolute d-flex align-items-center justify-content-end', { invisible: !isNextShowed })}>
                  <div
                    role="button"
                    tabIndex="0"
                    onClick={this.onNextClick}
                    onKeyPress={this.onNextClick}
                    className="game-row-odds-next-btn d-flex justify-content-center align-items-center position-relative"
                  >
                    <IconArrowControl />
                  </div>
                </div>
              </div>

              <LinkComponent
                to={gameUrl}
                locale={locale}
                className="d-flex mr-1_5 text-extra-3 text-small"
                onClick={this.onClick}
              >
                {!isResults && (
                  <span className="game-row-odds-counter d-flex align-items-center position-relative">
                    {!!oddsCount && oddsCount}
                    <IconChevron width="12" height="12" color={colors.cpExtra3} />
                  </span>
                )}
              </LinkComponent>
            </div>

            {isWatchlist && <WatchlistMenu updateWatchlist={updateWatchlist} game={game} />}
          </div>
        </div>
      </div>
    );
  }
}
