import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import { RouteLink } from 'components/route-link/route-link';
import { SportTypeIcon } from 'components/sport-type-icon/sport-type-icon';
import { IconArrowBottom } from 'components/icons/icon-arrow-bottom/icon-arrow-bottom';
import { TODAY, TOMORROW } from '../../../constants';

class GameHeadUI extends Component {
  static propTypes = {
    locale: PropTypes.string.isRequired,
    sportId: PropTypes.number.isRequired,
    leagueId: PropTypes.number.isRequired,
    leagueName: PropTypes.string.isRequired,
    isLeagueType: PropTypes.bool.isRequired,
    groupDate: PropTypes.string,
    withTableTitle: PropTypes.bool.isRequired,
    isWatchlist: PropTypes.bool.isRequired,
    isResults: PropTypes.bool.isRequired,
    isSchedule: PropTypes.bool.isRequired,
    isClickable: PropTypes.bool.isRequired,
    isOpen: PropTypes.bool,
    toggleOpenTable: PropTypes.func,
    tableId: PropTypes.number,
    isAllowToggle: PropTypes.bool,
    sendLeagueClickFeedback: PropTypes.func.isRequired,
  };

  static defaultProps = {
    groupDate: null,
    isAllowToggle: true,
    isOpen: true,
    toggleOpenTable: null,
    tableId: null,
  };

  onClick = () => {
    const { sendLeagueClickFeedback, leagueId, isClickable } = this.props;

    if (isClickable) {
      sendLeagueClickFeedback(leagueId);
    }
  };

  render() {
    const {
      locale,
      isLeagueType,
      sportId,
      leagueId,
      leagueName,
      groupDate,
      withTableTitle,
      isWatchlist,
      isSchedule,
      isResults,
      isClickable,
      isOpen,
      toggleOpenTable,
      tableId,
      isAllowToggle
    } = this.props;
    const LinkComponent = isClickable ? RouteLink : 'span';
    const isButtonVisible = !isResults && !isSchedule && !isWatchlist;

    return (
      <div className={classNames('betting-table-head d-flex flex-nowrap justify-content-between align-items-center align-items-sm-end pb-sm-0_25 bg-main-2', {
        'with-dropdown': isWatchlist,
        'is-open': isOpen,
      })}
      >
        {withTableTitle
          ? (
            <div className="betting-table-head-content-container pr-sm-1">
              {isLeagueType
                ? (
                  <div className="d-flex align-items-center caption">
                    {isButtonVisible && isAllowToggle && (
                      <div
                        role="button"
                        tabIndex="0"
                        className="betting-table-head-collapse d-flex align-items-center justify-content-center pl-0_5"
                        onClick={toggleOpenTable}
                        onKeyPress={toggleOpenTable}
                        data-table-id={tableId}
                      >
                        <IconArrowBottom className="icon-arrow-bottom" backgroundColor="transparent" />
                      </div>
                    )}
                    <div className="pl-1_5 caption text-extra-2">
                      {groupDate === TODAY && <FormattedMessage id="general.today" />}
                      {groupDate === TOMORROW && <FormattedMessage id="general.tomorrow" />}
                      {groupDate !== TODAY && groupDate !== TOMORROW && <span>{groupDate}</span>}
                    </div>
                  </div>
                )
                : (
                  <div className="d-flex align-items-center">
                    {isButtonVisible && isAllowToggle && (
                      <div
                        role="button"
                        tabIndex="0"
                        className="betting-table-head-collapse d-flex align-items-center justify-content-center pl-0_5"
                        onClick={toggleOpenTable}
                        onKeyPress={toggleOpenTable}
                        data-table-id={tableId}
                      >
                        <IconArrowBottom className="icon-arrow-bottom" backgroundColor="transparent" />
                      </div>
                    )}
                    <SportTypeIcon sportId={sportId} width="12" height="12" isActive className="sport-type-icon d-flex mb-0_25 ml-2 ml-sm-1" />
                    <LinkComponent
                      to={`/league/${sportId}/${leagueId}`}
                      locale={locale}
                      className={classNames('betting-table-head-name caption ml-1 text-extra-2 text-truncate', { 'is-clickable': isClickable })}
                      onClick={this.onClick}
                    >
                      {leagueName}
                    </LinkComponent>
                  </div>
                )}
            </div>
          )
          : (
            isButtonVisible && isAllowToggle && (
              <div
                role="button"
                tabIndex="0"
                className="betting-table-head-collapse d-flex align-items-center justify-content-center pl-0_5"
                onClick={toggleOpenTable}
                onKeyPress={toggleOpenTable}
                data-table-id={tableId}
              >
                <IconArrowBottom className="icon-arrow-bottom" backgroundColor="transparent" />
              </div>
            )
          )}
      </div>
    );
  }
}

export const GameHead = React.memo(GameHeadUI);
