import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './betting-table.scss';

export const BettingTable = ({ children, className, isOpen }) => (
  <div className={classnames('betting-table mb-1_5', className, { 'is-open': isOpen })}>
    {children}
  </div>
);

BettingTable.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool,
};

BettingTable.defaultProps = {
  className: null,
  isOpen: false,
};
