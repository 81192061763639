import React from 'react';
import PropTypes from 'prop-types';

export const IconDotMenu = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <path fill="#FFF" fillOpacity=".4" d="M11 9.5c0 .828-.672 1.5-1.5 1.5S8 10.328 8 9.5 8.672 8 9.5 8s1.5.672 1.5 1.5zm0 5c0 .828-.672 1.5-1.5 1.5S8 15.328 8 14.5 8.672 13 9.5 13s1.5.672 1.5 1.5zm0-10c0 .828-.672 1.5-1.5 1.5S8 5.328 8 4.5 8.672 3 9.5 3s1.5.672 1.5 1.5z" />
  </svg>
);

IconDotMenu.propTypes = {
  className: PropTypes.string,
};

IconDotMenu.defaultProps = {
  className: null,
};
