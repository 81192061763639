import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const GamesTablePreloaderRow = () => (
  <div className="d-none d-sm-block game-row bg-main-2">
    <div className="betting-table-row d-flex">
      <div className="betting-table-link preloader flex-fill" />
      <div className="odds-container d-flex align-items-center ml-2">
        <div className="betting-table-preloader odds-cell is-not-allowed" />
        <div className="betting-table-preloader odds-cell mx-1 is-not-allowed" />
        <div className="betting-table-preloader odds-cell is-not-allowed" />
        <div className="betting-table-preloader odds-cell is-not-allowed d-none d-xl-flex" />
        <div className="betting-table-preloader odds-cell is-not-allowed d-none d-xl-flex" />
        <div className="betting-table-preloader odds-cell is-not-allowed d-none d-xl-flex" />
        <div className="betting-table-preloader odds-cell is-not-allowed d-none d-xl-flex" />
        <div className="betting-table-preloader odds-cell is-not-allowed" />
      </div>
    </div>
  </div>
);

export const GamesTablePreloader = ({ withHeader, className }) => (
  <div className={classNames('betting-table games-table', className)}>
    {withHeader && <div className="games-table-preloader-header preloader mb-1 ml-2 ml-sm-0 bg-main-2" />}
    <div className="betting-table-head bg-main-2" />
    <div className="games-table-preloader-mobile preloader d-sm-none" />

    {[1, 2, 3, 4, 5, 6].map(row => <GamesTablePreloaderRow key={row} />)}
  </div>
);

GamesTablePreloader.propTypes = {
  withHeader: PropTypes.bool,
  className: PropTypes.string,
};

GamesTablePreloader.defaultProps = {
  withHeader: true,
  className: null,
};
