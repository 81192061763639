import React, { Fragment, Component, createRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as R from 'ramda';
import qs from 'qs';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { getConfig, getMainLineMarkets, isEmptyOrNil } from 'core/helpers';
import {
  BET_SLIP_STATE, NO_RECOMMENDATION_ID, STAKE_TYPES_WITH_3WAY_MAIN_MARKET
} from 'core/constants';

import Collapse from 'reactstrap/lib/Collapse';
import { FormattedTag } from 'components/formatted-tag/formatted-tag';
import { RouteLink } from 'components/route-link/route-link';
import { DateField } from 'components/betting-table/date-field/date-field';
import { GameStatus } from 'components/betting-table/game-status/game-status';
import { TeamColumn } from 'components/betting-table/team-column/team-column';
import { IconLivestreamSmall } from 'components/icons/icon-livestream-small/icon-livestream-small';
import { OddsCell } from 'components/betting-table/odds-cell/odds-cell';
import { prepareHomeScore, prepareAwayScore } from 'helpers/score';
import { setEmptyStakes } from 'helpers/stake';
import { GA } from 'helpers/ga';

import { SPORTS_WITH_3WAY_MAIN_MARKET, SPORTS_WITHOUT_LIVE_SCORE, PAGE_NAMES } from '../../../constants';

import './game-row-mobile.scss';

const IS_LIVE_STREAMS_ENABLED = getConfig('IS_LIVE_STREAMS_ENABLED');

const SCROLL_OFFSET = 100;

export class GameRowMobile extends Component {
  static propTypes = {
    locale: PropTypes.string.isRequired,
    game: PropTypes.shape().isRequired,
    toggleStake: PropTypes.func.isRequired,
    isLeagueType: PropTypes.bool,
    stakeTypes: PropTypes.arrayOf(PropTypes.shape()),
    betSlipStakes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    betSlipState: PropTypes.oneOf([
      BET_SLIP_STATE.DEFAULT,
      BET_SLIP_STATE.SUSPENDED,
      BET_SLIP_STATE.BET_FACTOR_DECREASED,
      BET_SLIP_STATE.SUCCESS,
      BET_SLIP_STATE.ERROR,
    ]).isRequired,
    isWatchlist: PropTypes.bool.isRequired,
    updateWatchlist: PropTypes.func,
    isResults: PropTypes.bool.isRequired,
    isSchedule: PropTypes.bool.isRequired,
    pageName: PropTypes.string.isRequired,
    recommendationRef: PropTypes.string,
    isOpen: PropTypes.bool.isRequired,
    sendEventClickFeedback: PropTypes.func.isRequired,
    sectionBeforeEventPage: PropTypes.string,
    liveSportId: PropTypes.number,
  };

  static defaultProps = {
    isLeagueType: false,
    updateWatchlist: null,
    stakeTypes: null,
    recommendationRef: null,
    sectionBeforeEventPage: null,
    liveSportId: null,
  };

  oddsRef = createRef();

  resizeWindowSubsription;

  prevClientX = null;

  prevClientY = null;

  state = {
    isDeleteActive: false,
    isTouchXDisabled: false,
    isTouchYDisabled: false,
    isPrevShowed: false,
    isNextShowed: false,
  };

  componentDidMount() {
    const odds = this.oddsRef && this.oddsRef.current;

    if (odds) {
      const { scrollWidth, clientWidth } = odds;
      this.resizeWindowSubsription = fromEvent(window, 'resize').pipe(debounceTime(200)).subscribe(this.onScroll);

      if (scrollWidth > clientWidth) {
        this.setIsNextShowed(true);
      }
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !R.equals(this.props, nextProps) || !R.equals(this.state, nextState);
  }

  componentDidUpdate(prevProps, prevState) {
    const { isWatchlist } = this.props;
    const { isDeleteActive, isTouchYDisabled } = this.state;
    const { isDeleteActive: prevIsDeleteActive, isTouchYDisabled: prevIsTouchYDisabled } = prevState;
    const { game: { stakeTypes: prevStakeTypes } } = prevProps;
    const { game: { stakeTypes } } = this.props;

    if (isWatchlist) {
      if (prevIsDeleteActive !== isDeleteActive) {
        if (isDeleteActive) {
          document.addEventListener('touchstart', this.checkOutsideTouch);
        } else {
          document.removeEventListener('touchstart', this.checkOutsideTouch);
        }
      }

      if (prevIsTouchYDisabled !== isTouchYDisabled) {
        if (isTouchYDisabled) {
          document.body.classList.add('scroll-disabled');
        } else {
          document.body.classList.remove('scroll-disabled');
        }
      }
    }

    if (!R.equals(prevStakeTypes, stakeTypes)) {
      this.onScroll();
    }
  }

  componentWillUnmount() {
    const { isWatchlist } = this.props;

    if (isWatchlist) {
      document.removeEventListener('touchstart', this.checkOutsideTouch);
      document.body.classList.remove('scroll-disabled');
    }

    if (this.resizeWindowSubsription) {
      this.resizeWindowSubsription.unsubscribe();
    }
  }

  setIsNextShowed = isNextShowed => this.setState({ isNextShowed });

  setIsPrevShowed = isPrevShowed => this.setState({ isPrevShowed });

  setIsDeleteActive = isDeleteActive => this.setState({ isDeleteActive });

  onOnTouchStart = (e) => {
    const { isWatchlist } = this.props;

    if (!isWatchlist) {
      return;
    }

    if (e.targetTouches.length === 1) {
      this.prevClientX = e.targetTouches[0].clientX;
      this.prevClientY = e.targetTouches[0].clientY;
    }
  };

  onOnTouchMove = (e) => {
    const { isWatchlist } = this.props;
    const { isDeleteActive, isTouchXDisabled, isTouchYDisabled } = this.state;

    if (!isWatchlist) {
      return;
    }

    if (e.targetTouches.length === 1) {
      const deltaX = e.targetTouches[0].clientX - this.prevClientX;
      const deltaXAbs = Math.abs(deltaX);
      const deltaYAbs = Math.abs(e.targetTouches[0].clientY - this.prevClientY);
      let isXDisabled = isTouchXDisabled;
      let isYDisabled = isTouchYDisabled;

      if ((deltaX > 10 || deltaYAbs > 10) && !isTouchXDisabled && !isTouchYDisabled) {
        isXDisabled = deltaXAbs === deltaYAbs || deltaYAbs > deltaXAbs;
        isYDisabled = deltaXAbs > deltaYAbs;

        this.setState({
          isTouchXDisabled: isXDisabled,
          isTouchYDisabled: isYDisabled,
        });
      }

      if (!isXDisabled && !isDeleteActive && deltaX < -20) {
        this.setIsDeleteActive(true);
      } else if (isDeleteActive && deltaX > 20) {
        this.setIsDeleteActive(false);
      }
    }
  };

  onTouchEnd = () => {
    this.setState({
      isTouchXDisabled: false,
      isTouchYDisabled: false,
    });
  };

  checkOutsideTouch = (e) => {
    const {
      isWatchlist,
      game: { eventId },
    } = this.props;
    const { isDeleteActive } = this.state;

    if (!isWatchlist) {
      return;
    }

    if (isDeleteActive) {
      const path = e.path || (e.composedPath && e.composedPath());
      const { touchedEventId } = R.compose(R.find(R.prop('touchedEventId')), R.pluck('dataset'))(path) || {};

      if (Number(touchedEventId) !== eventId) {
        this.setIsDeleteActive(false);
      }
    }
  };

  onRemoveClick = () => {
    const { isWatchlist, updateWatchlist, game } = this.props;

    if (isWatchlist && updateWatchlist) {
      updateWatchlist(game);
      GA.event({
        category: 'watchlist',
        label: 'delete-page',
      });
    }
  };

  onClick = () => {
    const {
      isWatchlist,
      isSchedule,
      pageName,
      game: {
        eventNumber,
        isLive,
        eventStatus,
        recommendationId,
      },
      sendEventClickFeedback,
    } = this.props;
    const isEventInLive = isLive || eventStatus !== null;
    let category;
    let label;

    if (isWatchlist) {
      category = 'watchlist';
      label = 'event-click';
    } else if (isSchedule) {
      category = 'calendar';
      label = 'event-click';
    } else if (pageName === PAGE_NAMES.HOME) {
      category = 'go-to-event-page';

      if (isEventInLive) {
        label = 'inplay-section-main-to-event';
      } else {
        label = 'upcoming-section-main-to-event';
      }
    } else if (pageName === PAGE_NAMES.SPORT) {
      category = 'go-to-event-page';

      if (isEventInLive) {
        label = 'inplay-section-sports-to-event';
      } else {
        label = 'upcoming-section-sports-to-event';
      }
    } else if (pageName === PAGE_NAMES.LEAGUE) {
      category = 'go-to-event-page';

      if (isEventInLive) {
        label = 'inplay-section-league-to-event';
      } else {
        label = 'upcoming-section-league-to-event';
      }
    } else if (pageName === PAGE_NAMES.LIVE) {
      category = 'go-to-event-page';
      label = 'main-inplay-to-event';
    }

    if (category && label) {
      GA.event({
        category,
        label,
      });
    }

    sendEventClickFeedback(eventNumber, isEventInLive, recommendationId);
  };

  onScroll = () => {
    const { isPrevShowed, isNextShowed } = this.state;

    if (this.oddsRef && this.oddsRef.current) {
      const { scrollWidth, clientWidth, scrollLeft } = this.oddsRef.current;
      const preparedWidth = Math.ceil(scrollLeft + clientWidth);

      if (isPrevShowed) {
        if (scrollLeft === 0) {
          this.setIsPrevShowed(false);
        }
      } else if (scrollLeft > 0) {
        this.setIsPrevShowed(true);
      }

      if (isNextShowed) {
        if (preparedWidth >= scrollWidth) {
          this.setIsNextShowed(false);
        }
      } else if (preparedWidth < scrollWidth) {
        this.setIsNextShowed(true);
      }
    }
  };

  onPrevClick = () => {
    const odds = this.oddsRef && this.oddsRef.current;

    if (odds) {
      const { scrollLeft } = odds;
      odds.scrollTo({
        left: scrollLeft - SCROLL_OFFSET,
        behavior: 'smooth',
      });
    }
  };

  onNextClick = () => {
    const odds = this.oddsRef && this.oddsRef.current;

    if (odds) {
      const { scrollLeft } = odds;
      odds.scrollTo({
        left: scrollLeft + SCROLL_OFFSET,
        behavior: 'smooth',
      });
    }
  };

  onTouch = (e) => {
    e.stopPropagation();
  };

  render() {
    const {
      locale,
      game,
      toggleStake,
      isLeagueType,
      betSlipStakes,
      betSlipState,
      isWatchlist,
      isResults,
      isSchedule,
      pageName,
      recommendationRef,
      isOpen,
      sectionBeforeEventPage,
      liveSportId,
    } = this.props;
    const { isPrevShowed, isNextShowed, isDeleteActive } = this.state;
    const {
      eventId,
      eventNumber,
      oddsCount,
      isBetAllowed,
      isLive,
      teamA,
      teamB,
      timestamp,
      stakeTypes,
      sportId,
      passedTime,
      eventStatus,
      homeScore,
      awayScore,
      isOutright,
      leagueId,
      teamALogo,
      teamBLogo,
      hasLiveStream,
      setAltScore,
      recommendationId,
    } = game;
    let is3way = SPORTS_WITH_3WAY_MAIN_MARKET.includes(sportId) && !isOutright;
    const marketsList = getMainLineMarkets({
      stakeTypes, isLive, eventStatus, betSlipStakes, betSlipState
    });
    const isAnyMarketExists = !!marketsList.length
    && !!marketsList.filter(({ marketStake }) => marketStake && !!marketStake.length).length;
    const isOneMarket = !!marketsList.length
    && marketsList.filter(({ marketStake }) => marketStake && !!marketStake.length).length < 2;

    const withRecommendation = pageName === PAGE_NAMES.LIVE && recommendationRef;
    const preparedRecommendationId = withRecommendation ? recommendationId || NO_RECOMMENDATION_ID : null;
    const isEventInLive = isLive || eventStatus !== null;
    const gameUrl = `/event/${eventId}?${qs.stringify({
      isLive: isEventInLive,
      recommendationId: preparedRecommendationId,
      recommendationRef,
      sectionBeforeEventPage,
    }, { skipNulls: true })}`;
    const isClickable = !isResults && (!isSchedule || (isSchedule && eventId && oddsCount));
    const LinkComponent = isClickable ? RouteLink : 'span';
    const preparedHomeScore = prepareHomeScore(homeScore, sportId, setAltScore);
    const preparedAwayScore = prepareAwayScore(awayScore, sportId, setAltScore);

    const oddProps = {
      isBetAllowed,
      toggleStake,
      eventId,
      eventNumber,
      teamA,
      teamB,
      betSlipStakes,
      isEventInLive,
      betSlipState,
      isOutright,
      leagueId,
      isResults,
      pageName,
      isWatchlist,
      isSchedule,
      recommendationId,
      liveSportId,
    };

    return (
      <div
        onTouchStart={isWatchlist ? this.onOnTouchStart : null}
        onTouchMove={isWatchlist ? this.onOnTouchMove : null}
        onTouchEnd={isWatchlist ? this.onTouchEnd : null}
        data-touched-event-id={eventId}
        className={classNames('game-row-mobile game-row-wrapper position-relative d-flex', {
          'is-delete-active': isDeleteActive,
          'with-dropdown': isWatchlist,
        })}
      >
        <Collapse isOpen={isOpen}>
          <div className="game-row bg-main-2">
            <div className={classNames('betting-table-row d-flex flex-nowrap justify-content-between flex-column px-2', { 'is-markets': isAnyMarketExists })}>
              <LinkComponent
                to={gameUrl}
                locale={locale}
                className="pt-1_5 d-flex align-items-center"
                onClick={this.onClick}
              >
                <div className="flex-fill overflow-hidden">
                  <TeamColumn name={teamA} logo={teamALogo} sportId={sportId} />
                  {!isOutright && <TeamColumn name={teamB} logo={teamBLogo} sportId={sportId} className="mb-0_5" />}
                </div>

                <div className="game-row-mobile-match-info d-flex flex-shrink-0">
                  {IS_LIVE_STREAMS_ENABLED && hasLiveStream && <IconLivestreamSmall className="align-self-start mr-2" />}

                  <div className={classNames('d-flex flex-column caption align-items-end', {
                    'text-extra-3': !isEventInLive,
                    'justify-content-center': isResults,
                    'justify-content-between': !isResults,
                  })}
                  >
                    <span className="animate-minute overflow-hidden d-flex align-items-center caption">
                      {isEventInLive
                        ? (
                          <GameStatus
                            locale={locale}
                            sportId={sportId}
                            eventStatus={eventStatus}
                            passedTime={passedTime}
                            className="game-row-mobile-status text-truncate text-extra-2"
                          />
                        )
                        : (
                          <DateField
                            locale={locale}
                            isTimeFormat={isLeagueType || isResults || isSchedule}
                            timestamp={timestamp}
                          />
                        )}
                    </span>

                    {!!oddsCount && (
                      <span className="position-relative d-flex align-items-center justify-content-end text-extra-3">
                        +{oddsCount}
                      </span>
                    )}
                  </div>

                  {(isEventInLive || isResults) && !SPORTS_WITHOUT_LIVE_SCORE.includes(sportId) && (
                    <Fragment>
                      <span className="game-row-mobile-score-divider mx-2" />
                      <div className={classNames('d-flex flex-column justify-content-between text-small font-weight-bold text-right', { 'text-success': !isResults })}>
                        {preparedHomeScore === '' ? <span>&nbsp;</span> : <span>{preparedHomeScore}</span>}
                        {preparedAwayScore === '' ? <span>&nbsp;</span> : <span>{preparedAwayScore}</span>}
                      </div>
                    </Fragment>
                  )}
                </div>
              </LinkComponent>

              <div className="game-row-mobile-odds-wrapper position-absolute">
                <div
                  ref={this.oddsRef}
                  onScroll={this.onScroll}
                  onTouchMove={this.onTouch}
                  className="game-row-mobile-odds-scroll-bar d-flex"
                >
                  {isAnyMarketExists ? (
                    marketsList.map(({
                      stakeTypeId,
                      stakeTypeName,
                      marketStake,
                      isTotal,
                      isHandicap,
                    }, index) => {
                      is3way = STAKE_TYPES_WITH_3WAY_MAIN_MARKET.includes(stakeTypeId);
                      const isLastMarket = marketsList.length === index + 1;
                      const marketStakeWithEmptyValue = setEmptyStakes(marketStake, is3way);

                      return !R.isNil(marketStakeWithEmptyValue) && (
                        <div key={stakeTypeId} className={classNames('game-row-mobile-odds-item d-flex flex-column flex-shrink-0 position-relative', { 'is-one-market': isOneMarket, 'with-draw': is3way, 'mr-1_5': !isLastMarket })}>
                          <div className="market-name overflow-hidden caption position-absolute mb-0_5 mt-1_5">
                            <span className="text-nowrap text-extra-3">
                              {stakeTypeName}
                            </span>
                          </div>
                          <div className="game-row-mobile-odds-group mt-4 flex-shrink-0">
                            {marketStakeWithEmptyValue.map(stake =>
                              !isEmptyOrNil(stake) ? (
                                <OddsCell
                                  key={stake.stakeId}
                                  stake={stake}
                                  isHandicap={isHandicap}
                                  isTotal={isTotal}
                                  {...oddProps}
                                />
                              ) : (
                                <div className="odd-cell is-not-allowed d-flex justify-content-center align-items-center text-extra-3">
                                  &#8212;
                                </div>
                              ))}
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className={classNames('game-row-mobile-odds-item w-100 mt-1_5', { 'with-draw': is3way })}>
                      <div className="game-row-mobile-odds-group">
                        <div className="odd-cell is-not-allowed d-flex justify-content-center align-items-center text-extra-3">
                          &#8212;
                        </div>
                        <div className="odd-cell is-not-allowed d-flex justify-content-center align-items-center text-extra-3">
                          &#8212;
                        </div>
                        {is3way && (
                          <div className="odd-cell is-not-allowed d-flex justify-content-center align-items-center text-extra-3">
                            &#8212;
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>

                <div
                  role="button"
                  tabIndex="0"
                  onClick={this.onPrevClick}
                  onKeyPress={this.onPrevClick}
                  className={classNames('game-row-mobile-odds-prev position-absolute', { invisible: !isPrevShowed })}
                />

                <div
                  role="button"
                  tabIndex="0"
                  onClick={this.onNextClick}
                  onKeyPress={this.onNextClick}
                  className={classNames('game-row-mobile-odds-next position-absolute', { invisible: !isNextShowed })}
                />
              </div>
            </div>
          </div>
        </Collapse>

        {isWatchlist && (
          <div
            role="button"
            tabIndex="0"
            onClick={this.onRemoveClick}
            onKeyPress={this.onRemoveClick}
            className="watchlist-delete-button bg-danger d-flex align-items-center justify-content-center flex-shrink-0"
          >
            <FormattedTag id="general.delete" className="text-small font-weight-bold" />
          </div>
        )}
      </div>
    );
  }
}
