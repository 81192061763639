import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../customizations/js/color-variables';

export const IconChevron = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h12v12H0z" />
      <path fill={colors.cpExtra2} d="M5.06 2L4 3.06 6.938 6 4.001 8.937l1.061 1.061L9.06 6z" />
    </g>
  </svg>
);

IconChevron.propTypes = {
  className: PropTypes.string,
};
IconChevron.defaultProps = {
  className: '',
};
