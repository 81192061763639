import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'reactstrap/lib/Dropdown';
import DropdownMenu from 'reactstrap/lib/DropdownMenu';
import DropdownItem from 'reactstrap/lib/DropdownItem';
import DropdownToggle from 'reactstrap/lib/DropdownToggle';

import { FormattedTag } from 'components/formatted-tag/formatted-tag';
import { IconDotMenu } from 'components/icons/icon-dot-menu/icon-dot-menu';
import { GA } from 'helpers/ga';

import './watchlist-menu.scss';

export class WatchlistMenu extends Component {
  static propTypes = {
    updateWatchlist: PropTypes.func,
    game: PropTypes.shape().isRequired,
  };

  static defaultProps = {
    updateWatchlist: null,
  };

  state = {
    isOpen: false,
  };

  toggle = () => this.setState(prevState => ({ isOpen: !prevState.isOpen }));

  onClick = () => {
    const { updateWatchlist, game } = this.props;

    if (updateWatchlist) {
      updateWatchlist(game);
      GA.event({
        category: 'watchlist',
        label: 'delete-page',
      });
    }
  };

  render() {
    const { isOpen } = this.state;

    return (
      <div className="watchlist-menu d-none d-sm-flex">
        <Dropdown isOpen={isOpen} toggle={this.toggle}>
          <DropdownToggle className="watchlist-menu-toggle p-0 rounded-0 h-100">
            <IconDotMenu />
          </DropdownToggle>
          <DropdownMenu className="">
            <FormattedTag
              id="general.delete"
              tag={DropdownItem}
              onClick={this.onClick}
              onKeyPress={this.onClick}
            />
          </DropdownMenu>
        </Dropdown>
      </div>
    );
  }
}
